import React from 'react'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { H1, MarkdownHandler } from '@system'
import { Box } from '@material-ui/core'
import renderBloks from '@renderBloks'

const useStyles = makeStyles((theme) => ({
  titleAndTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '3rem 3rem 3rem 0',
    flexGrow: 1,
    flexBasis: 0,
    [theme.breakpoints.down('sm')]: {
      padding: '3rem 0',
    },
  },
  description: {
    marginTop: '3vh',
    marginBottom: '3vh',
    lineHeight: '30px',
    color: theme.palette.text.tertiary,
  },
}))

const InteractiveQuizResultTitleAndDescription = (props) => {
  const { title, description } = props.blok
  const { downloadCta } = props
  const classes = useStyles()

  return (
    <SbEditable content={props.blok}>
      <Box className={classes.titleAndTextContainer}>
        <H1 component="h2" color="primary">
          {title}
        </H1>
        <MarkdownHandler className={classes.description}>
          {description}
        </MarkdownHandler>
        {renderBloks(downloadCta)}
      </Box>
    </SbEditable>
  )
}

export default InteractiveQuizResultTitleAndDescription
